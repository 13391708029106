import isPropValid from '@emotion/is-prop-valid';
import { Provider } from '@m/components/ui/provider';
import theme from '@m/theme/theme';
import { ConfigProvider } from 'antd';
import { ThemeProvider, StyleSheetManager } from 'styled-components';
// This implements the default behavior from styled-components v5
export function shouldForwardProp(propName: string, target: any) {
  if (typeof target === 'string') {
    return isPropValid(propName);
  }
  return true;
}
export interface ProvidersProps {
  children?: React.ReactNode;
}

export default function SharedProviders({ children }: ProvidersProps) {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Provider defaultTheme="light">
        <ConfigProvider>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ConfigProvider>
      </Provider>
    </StyleSheetManager>
  );
}
