import * as React from 'react';
import type { SVGProps } from 'react';

const SvgLocationGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g fillRule="evenodd">
      <path d="M20.37 11.08a1 1 0 0 1 .97.77l2.59 10.92a1 1 0 0 1-.98 1.23H1.05a1 1 0 0 1-.98-1.23l2.59-10.92a1 1 0 0 1 .97-.77h2.94c.16.42.34.85.53 1.3H3.86l-.51 1.72h3.92a.2.2 0 0 0 .2-.15l.13-.46c.31.66.65 1.31 1.02 1.9h.05c-.11 0-.2.07-.24.16l-.01.06-.21 2.32c0 .12.08.22.19.25h7.14a.25.25 0 0 0 .25-.2v-.07l-.2-2.3a.25.25 0 0 0-.21-.22c.37-.59.7-1.24 1.02-1.9l.14.46a.2.2 0 0 0 .14.14h3.97l-.5-1.72H16.9c.19-.44.37-.87.53-1.29zm-4.77 8.53H8.4a.5.5 0 0 0-.46.32L7.9 20l-.37 1.89a.5.5 0 0 0 .4.58l.09.01h7.94a.5.5 0 0 0 .5-.5v-.1L16.08 20a.5.5 0 0 0-.5-.4Zm6.1 0-3.75.08a.3.3 0 0 0-.3.28l.01.07.37 2.04c.04.21.2.37.4.4l.1.01h3.55a.5.5 0 0 0 .5-.5l-.01-.1-.37-1.87a.5.5 0 0 0-.5-.4Zm-19.4 0a.5.5 0 0 0-.5.4l-.37 1.89a.5.5 0 0 0 .49.6h3.55a.5.5 0 0 0 .5-.42l.37-2.04a.3.3 0 0 0-.3-.35l-3.74-.07Zm18.38-4.3-3.4.07a.3.3 0 0 0-.28.28v.07l.37 2.05c.04.2.2.36.4.4h3.3a.5.5 0 0 0 .5-.5l-.02-.1-.37-1.87a.5.5 0 0 0-.5-.4m-17.36 0a.5.5 0 0 0-.5.4l-.37 1.88a.5.5 0 0 0 .49.6h3.2a.5.5 0 0 0 .49-.41L7 15.73a.3.3 0 0 0-.29-.35z" />
      <path
        fillRule="nonzero"
        d="M12 0c3.03 0 5.5 2.5 5.5 5 0 2-1.83 6-5.5 12C8.33 11 6.5 7 6.5 5c0-2.5 2.47-5 5.5-5m0 2.91A2.43 2.43 0 0 0 9.53 5.3c0 1.32 1.1 2.39 2.47 2.39a2.43 2.43 0 0 0 2.47-2.39c0-1.31-1.1-2.38-2.47-2.38Z"
      />
    </g>
  </svg>
);
export default SvgLocationGroup;
