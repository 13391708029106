import React, { lazy, Suspense } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import AuthenticatedRoute from 'apps/admin/components/AuthenticatedRoute';

const SiteSurveyForm = lazy(() => import('apps/admin/components/SiteSurveyForm'));
const SiteSurveysList = lazy(() => import('apps/admin/components/SiteSurveysList/SiteSurveysList'));

export const siteSurveyRoutesConfig = [
  {
    exact: true,
    path: '/site-survey',
    breadcrumb: '',
    component: Route,
    children: (
      <Suspense fallback={null}>
        <SiteSurveyForm />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/site-survey/my-surveys',
    breadcrumb: '',
    component: AuthenticatedRoute,
    children: <Redirect to="/surveys" />,
  },
  {
    exact: true,
    path: '/surveys',
    breadcrumb: '',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <SiteSurveysList />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/site-survey/:surveyId',
    breadcrumb: '',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <SiteSurveyForm isApprovalFlow />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/site-survey/:surveyId/edit',
    breadcrumb: '',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <SiteSurveyForm isEditing />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/site-survey/:surveyId/recreate',
    breadcrumb: '',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <SiteSurveyForm isRecreating />
      </Suspense>
    ),
  },
];

export default function SiteSurveyRoutes() {
  return (
    <Switch>
      {siteSurveyRoutesConfig.map(
        ({ component: Component, breadcrumb, ...props }) =>
          Component && <Component key={props.path} {...props} />,
      )}
    </Switch>
  );
}
