export function getBadgeData() {
  const { hostname } = window.location;
  const stagingRegex = /\w+-staging.metropolis.io/;
  const developmentRegex = /\w+-development.metropolis.io/;
  const devRegex = /\w+-dev.metropolis.io/;
  const localRegex = /^localhost$/;
  const localNetworkRegex = /\.local$/;

  if (developmentRegex.test(hostname) || devRegex.test(hostname)) {
    return {
      label: 'development',
      shortLabel: 'DEV',
      color: '#de1d1d',
    };
  }
  if (stagingRegex.test(hostname)) {
    return {
      label: 'staging',
      shortLabel: 'STG',
      color: '#de6207',
    };
  }
  if (localRegex.test(hostname) || localNetworkRegex.test(hostname)) {
    return {
      label: 'local',
      shortLabel: 'LOC',
      color: '#ad1457',
    };
  }
  return null;
}
