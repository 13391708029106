import FontNeueMontreal from '@m/assets/fonts/neue-montreal/FontNeueMontreal';
import { BrowserRouter } from 'react-router-dom';

import SharedProviders from './shared';

export default function Providers({ children }: { children?: React.ReactNode }) {
  return (
    <SharedProviders>
      <FontNeueMontreal />
      <BrowserRouter>{children}</BrowserRouter>
    </SharedProviders>
  );
}
