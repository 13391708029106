import withStyledSystem, { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';
import styled from 'styled-components';

export const Wrapper = styled.div<StyledSystemProps>`
  position: relative;
  display: flex;
  width: auto;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 18px;
  padding-left: 9px;
  padding-right: 9px;
  margin-left: 16px;
  border-radius: 9px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.04em;
  overflow: hidden;
  text-transform: uppercase;
  text-align: center;
  transition: width 150ms ease !important;
  ${withStyledSystem}
`;
