import { applyReducers } from '@m/effector-utils';
import { Option } from '@m/types';
import { createStore, Effect, Event } from 'effector';
import _ from 'lodash';

import { IntakePartner } from 'apps/admin/services';

import {
  getEnterpriseList,
  createEnterprise,
  clearSelectedEnterprise,
  getEnterpriseById,
  updateEnterprise,
  deleteEnterprise,
  createEnterpriseSubscription,
  getEntSubRedemptionCode,
  getEntSubMembers,
  getEnterpriseSubscriptions,
  getEnterpriseSubscriptionDetail,
  updateEnterpriseSubscription,
  deleteEntSubMembers,
  addUserToEntSub,
  getEntLocationCapacityList,
  getEntLocationTypeList,
  getEnterpriseLocations,
  createEntLocation,
  getEntLocationById,
  updateEntLocation,
  getSignTypeList,
  getSignRemovalReasonList,
  getEntLocSigns,
  createEntLocSign,
  getEntLocSignById,
  updateEntLocSign,
  resetSelectedEntLocSign,
  removeEntLocSign,
  getShortCodesBySignId,
  getPaymentMethod,
  getAllPaymentMethods,
  createNewACHPaymentMethod,
  deletePaymentMethod,
  getEnterpriseIntakePartners,
  createEnterpriseIntakePartner,
  updateEnterpriseIntakePartner,
} from './actions';

import type { DoneHandler } from '@m/effector-utils';
import type {
  Enterprise,
  EnterpriseSubscriptionDetail,
  EntSubRedemptionCode,
  EntSubMember,
  EntSubListItem,
  EntLocation,
  EntLocationCapacity,
  EntLocationType,
  EntLocSignData,
  EntLocSignDataMap,
  EntLocSignNameMap,
  EntLocSign,
  SignShortCode,
  PaymentMethod,
  EntSubBillingEnrollmentState,
  EntSubBillingAllowedActions,
  EnterpriseAdminFlags,
  EntSubSiteDetailSimple,
  Site,
} from '@m/types/api';
import type { APIResponse } from '@m/utils/http';

type EntBillingMethodMap = {
  [key: number]: PaymentMethod | null;
};

type EntBillingAllMethodMap = {
  [key: number]: PaymentMethod[] | null;
};

type EntSubscriptionMetaMap = {
  // map subscription.id key to redemption code & members data
  [key: number]: EntSubRedemptionCode & {
    subscribers: EntSubMember[];
    billingEnrollmentState: EntSubBillingEnrollmentState;
    allowedBillingActions: EntSubBillingAllowedActions;
    subSites: EntSubSiteDetailSimple[];
  };
};

type EnterpriseMetaMap = {
  [key: number]: {
    adminFlags?: EnterpriseAdminFlags;
  };
};

type SignCodeMap = {
  [signId: string]: SignShortCode[];
};

export type EnterpriseState = {
  enterprises: Enterprise[];
  enterpriseMetaMap: EnterpriseMetaMap;
  selectedEnterprise: Enterprise | null;
  enterpriseSubscriptions: EnterpriseSubscriptionDetail[] | null;
  lastCreated: Enterprise | null;
  selectedEntSubList: EntSubListItem[];
  selectedEntSub: EnterpriseSubscriptionDetail | null;
  subscriptionMetaMap: EntSubscriptionMetaMap;
  entBillingMethodMap: EntBillingMethodMap;
  entBillingAllMethodMap: EntBillingAllMethodMap;
  entLocationTypeList: EntLocationType[];
  entLocationCapacityList: EntLocationCapacity[];
  selectedEntLocationList: EntLocation[];
  selectedEntLocation: EntLocation | null;
  signTypeList: EntLocSignData[];
  signTypeMapId: EntLocSignDataMap;
  signTypeMapName: EntLocSignNameMap;
  signRemovalReasonList: Option[];
  selectedEntLocSignList: EntLocSign[];
  selectedEntLocSign: EntLocSign | null;
  signShortCodeMap: SignCodeMap;
  intakePartners: IntakePartner[];
};

export const initialState: EnterpriseState = {
  enterprises: [],
  enterpriseMetaMap: {},
  selectedEnterprise: null,
  enterpriseSubscriptions: null,
  lastCreated: null,
  selectedEntSubList: [],
  selectedEntSub: null,
  subscriptionMetaMap: {},
  entBillingMethodMap: {},
  entBillingAllMethodMap: {},
  entLocationCapacityList: [],
  entLocationTypeList: [],
  selectedEntLocationList: [],
  selectedEntLocation: null,
  signTypeList: [],
  signTypeMapId: {},
  signTypeMapName: {},
  signRemovalReasonList: [],
  selectedEntLocSignList: [],
  selectedEntLocSign: null,
  signShortCodeMap: {},
  intakePartners: [],
};

const store = createStore(initialState);

type Reducers = {
  getEnterpriseList: {
    action: Effect<Parameters<typeof getEnterpriseList>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getEnterpriseList>[0], EnterpriseState>;
  };
  createEnterprise: {
    action: Effect<Parameters<typeof createEnterprise>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof createEnterprise>[0], EnterpriseState>;
  };
  clearSelectedEnterprise: {
    action: Event<void>;
    reducer: (state: EnterpriseState) => EnterpriseState;
  };
  getEnterpriseById: {
    action: Effect<Parameters<typeof getEnterpriseById>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getEnterpriseById>[0], EnterpriseState>;
  };
  updateEnterprise: {
    action: Effect<Parameters<typeof updateEnterprise>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof updateEnterprise>[0], EnterpriseState>;
  };
  deleteEnterprise: {
    action: Effect<Parameters<typeof deleteEnterprise>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof deleteEnterprise>[0], EnterpriseState>;
  };
  createEnterpriseSubscription: {
    action: Effect<Parameters<typeof createEnterpriseSubscription>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof createEnterpriseSubscription>[0], EnterpriseState>;
  };
  getEntSubRedemptionCode: {
    action: Effect<Parameters<typeof getEntSubRedemptionCode>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getEntSubRedemptionCode>[0], EnterpriseState>;
  };
  getEntSubMembers: {
    action: Effect<Parameters<typeof getEntSubMembers>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getEntSubMembers>[0], EnterpriseState>;
  };
  getEnterpriseSubscriptions: {
    action: Effect<Parameters<typeof getEnterpriseSubscriptions>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getEnterpriseSubscriptions>[0], EnterpriseState>;
  };
  getEnterpriseSubscriptionDetail: {
    action: Effect<Parameters<typeof getEnterpriseSubscriptionDetail>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getEnterpriseSubscriptionDetail>[0], EnterpriseState>;
  };
  updateEnterpriseSubscription: {
    action: Effect<Parameters<typeof updateEnterpriseSubscription>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof updateEnterpriseSubscription>[0], EnterpriseState>;
  };
  deleteEntSubMembers: {
    action: Effect<Parameters<typeof deleteEntSubMembers>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof deleteEntSubMembers>[0], EnterpriseState>;
  };
  addUserToEntSub: {
    action: Effect<Parameters<typeof addUserToEntSub>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof addUserToEntSub>[0], EnterpriseState>;
  };
  getEntLocationCapacityList: {
    action: Effect<Parameters<typeof getEntLocationCapacityList>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getEntLocationCapacityList>[0], EnterpriseState>;
  };
  getEntLocationTypeList: {
    action: Effect<Parameters<typeof getEntLocationTypeList>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getEntLocationTypeList>[0], EnterpriseState>;
  };
  getEnterpriseLocations: {
    action: Effect<Parameters<typeof getEnterpriseLocations>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getEnterpriseLocations>[0], EnterpriseState>;
  };
  createEntLocation: {
    action: Effect<Parameters<typeof createEntLocation>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof createEntLocation>[0], EnterpriseState>;
  };
  getEntLocationById: {
    action: Effect<Parameters<typeof getEntLocationById>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getEntLocationById>[0], EnterpriseState>;
  };
  updateEntLocation: {
    action: Effect<Parameters<typeof updateEntLocation>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof updateEntLocation>[0], EnterpriseState>;
  };
  getSignTypeList: {
    action: Effect<Parameters<typeof getSignTypeList>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getSignTypeList>[0], EnterpriseState>;
  };
  getSignRemovalReasonList: {
    action: Effect<Parameters<typeof getSignRemovalReasonList>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getSignRemovalReasonList>[0], EnterpriseState>;
  };
  getEntLocSigns: {
    action: Effect<Parameters<typeof getEntLocSigns>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getEntLocSigns>[0], EnterpriseState>;
  };
  createEntLocSign: {
    action: Effect<Parameters<typeof createEntLocSign>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof createEntLocSign>[0], EnterpriseState>;
  };
  getEntLocSignById: {
    action: Effect<Parameters<typeof getEntLocSignById>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getEntLocSignById>[0], EnterpriseState>;
  };
  updateEntLocSign: {
    action: Effect<Parameters<typeof updateEntLocSign>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof updateEntLocSign>[0], EnterpriseState>;
  };
  resetSelectedEntLocSign: {
    action: Event<void>;
    reducer: (state: EnterpriseState) => EnterpriseState;
  };
  removeEntLocSign: {
    action: Effect<Parameters<typeof removeEntLocSign>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof removeEntLocSign>[0], EnterpriseState>;
  };
  getShortCodesBySignId: {
    action: Effect<Parameters<typeof getShortCodesBySignId>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getShortCodesBySignId>[0], EnterpriseState>;
  };
  getPaymentMethod: {
    action: Effect<Parameters<typeof getPaymentMethod>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getPaymentMethod>[0], EnterpriseState>;
  };
  getAllPaymentMethods: {
    action: Effect<Parameters<typeof getAllPaymentMethods>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getAllPaymentMethods>[0], EnterpriseState>;
  };
  createNewACHPaymentMethod: {
    action: Effect<Parameters<typeof createNewACHPaymentMethod>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof createNewACHPaymentMethod>[0], EnterpriseState>;
  };
  deletePaymentMethod: {
    action: Effect<Parameters<typeof deletePaymentMethod>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof deletePaymentMethod>[0], EnterpriseState>;
  };
  getEnterpriseIntakePartners: {
    action: Effect<Parameters<typeof getEnterpriseIntakePartners>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getEnterpriseIntakePartners>[0], EnterpriseState>;
  };
  createEnterpriseIntakePartner: {
    action: Effect<Parameters<typeof createEnterpriseIntakePartner>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof createEnterpriseIntakePartner>[0], EnterpriseState>;
  };
  updateEnterpriseIntakePartner: {
    action: Effect<Parameters<typeof updateEnterpriseIntakePartner>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof updateEnterpriseIntakePartner>[0], EnterpriseState>;
  };
};

export const reducers: Reducers = {
  // #region Enterprise
  getEnterpriseList: {
    action: getEnterpriseList,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      enterprises: success ? data.enterprises : state.enterprises,
    }),
  },
  createEnterprise: {
    action: createEnterprise,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      enterprises: success ? [data.enterprise, ...state.enterprises] : state.enterprises,
      lastCreated: success ? data.enterprise : state.lastCreated,
    }),
  },
  clearSelectedEnterprise: {
    action: clearSelectedEnterprise,
    reducer: (state) => ({
      ...state,
      selectedEnterprise: null,
    }),
  },
  getEnterpriseById: {
    action: getEnterpriseById,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      selectedEnterprise: success ? data.enterprise : null,
      enterpriseMetaMap: {
        ...state.enterpriseMetaMap,
        [data.enterprise.id]: {
          adminFlags: data.adminFlags,
        },
      },
    }),
  },
  updateEnterprise: {
    action: updateEnterprise,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      selectedEnterprise: success ? data.enterprise : state.selectedEnterprise,
      enterpriseMetaMap: {
        ...state.enterpriseMetaMap,
        [data.enterprise.id]: {
          adminFlags: data.adminFlags,
        },
      },
    }),
  },
  deleteEnterprise: {
    action: deleteEnterprise,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      selectedEnterprise: success ? data.enterprise : state.selectedEnterprise,
    }),
  },
  // #endregion Enterprise

  // #region Subscription
  createEnterpriseSubscription: {
    action: createEnterpriseSubscription,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      selectedEnterpriseSubscriptions: success
        ? data.enterpriseSubscription
        : state.selectedEnterprise,
      subscriptionMetaMap: {
        ...state.subscriptionMetaMap,
        [data.enterpriseSubscription.id]: {
          ...state.subscriptionMetaMap[data.enterpriseSubscription.id],
          subscribers: [],
        },
      },
    }),
  },
  getEntSubRedemptionCode: {
    action: getEntSubRedemptionCode,
    done: (state, { result: { success, data } = {}, params: { subId } }) => ({
      ...state,
      subscriptionMetaMap: {
        ...state.subscriptionMetaMap,
        [subId]: {
          ...state.subscriptionMetaMap[subId],
          ...data.subscriptionRedemptionCodes[0],
        },
      },
    }),
  },
  getEntSubMembers: {
    action: getEntSubMembers,
    done: (state, { result: { success, data } = {}, params: { subId } }) => ({
      ...state,
      subscriptionMetaMap: {
        ...state.subscriptionMetaMap,
        [subId]: {
          ...state.subscriptionMetaMap[subId],
          subscribers: data,
        },
      },
    }),
  },
  getEnterpriseSubscriptions: {
    action: getEnterpriseSubscriptions,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      selectedEntSubList: success ? data.enterpriseSubscriptions : [],
    }),
  },
  getEnterpriseSubscriptionDetail: {
    action: getEnterpriseSubscriptionDetail,
    done: (state, { result: { success, data } = {}, params: { subId } }) => ({
      ...state,
      selectedEntSub: success ? data.enterpriseSubscription : state.selectedEntSub,
      subscriptionMetaMap: {
        ...state.subscriptionMetaMap,
        [subId]: {
          ...state.subscriptionMetaMap[subId],
          billingEnrollmentState: data.billingEnrollmentState,
          allowedBillingActions: data.allowedBillingActions,
          subSites: data.sites.map(({ id, name, street, city, stateCode, zip }: Site) => ({
            id,
            name,
            address_1: street,
            city,
            stateCode,
            zipcode: zip,
          })),
        },
      },
    }),
  },

  updateEnterpriseSubscription: {
    action: updateEnterpriseSubscription,
    done: (state, { result: { success, data } = {}, params: { subId } }) => {
      if (success) {
        return {
          ...state,
          selectedEntSub: success ? data.enterpriseSubscription : state.selectedEntSub,
          subscriptionMetaMap: {
            ...state.subscriptionMetaMap,
            [subId]: {
              ...state.subscriptionMetaMap[subId],
              billingEnrollmentState: data.billingEnrollmentState,
              allowedBillingActions: data.allowedBillingActions,
              subSites: data.sites.map(({ id, name, street, city, stateCode, zip }: Site) => ({
                id,
                name,
                address_1: street,
                city,
                stateCode,
                zipcode: zip,
              })),
            },
          },
        };
      }

      return state;
    },
  },
  deleteEntSubMembers: {
    action: deleteEntSubMembers,
    done: (state, { result: { success, data } = {}, params: { subId } }) => ({
      ...state,
      subscriptionMetaMap: {
        ...state.subscriptionMetaMap,
        [subId]: {
          ...state.subscriptionMetaMap[subId],
          ...data,
        },
      },
    }),
  },
  addUserToEntSub: {
    action: addUserToEntSub,
    done: (state, { result: { success, data } = {}, params: { subId } }) =>
      success
        ? {
            ...state,
            subscriptionMetaMap: {
              ...state.subscriptionMetaMap,
              [subId]: {
                ...state.subscriptionMetaMap[subId],
                subscribers: [
                  data,
                  ...state.subscriptionMetaMap[subId].subscribers.filter(
                    ({ id }: { id: number }) => id !== data.id,
                  ),
                ],
              },
            },
          }
        : state,
  },
  // #endregion Subscription

  // #region Location
  getEntLocationCapacityList: {
    action: getEntLocationCapacityList,
    done: (state, { result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            entLocationCapacityList: _.orderBy(data, 'id'),
          }
        : state,
  },
  getEntLocationTypeList: {
    action: getEntLocationTypeList,
    done: (state, { result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            // GICS info: https://en.wikipedia.org/wiki/Global_Industry_Classification_Standard
            // Sort `type` options by GICS `id` in ascending order
            entLocationTypeList: data.sort((a: EntLocationType, b: EntLocationType) => a.id - b.id),
          }
        : state,
  },
  createEntLocation: {
    action: createEntLocation,
    done: (state, { result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            selectedEntLocationList: [data as EntLocation, ...state.selectedEntLocationList],
          }
        : state,
  },
  getEnterpriseLocations: {
    action: getEnterpriseLocations,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      selectedEntLocationList: success ? data : [],
    }),
  },
  getEntLocationById: {
    action: getEntLocationById,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      selectedEntLocation: success ? data : null,
    }),
  },
  updateEntLocation: {
    action: updateEntLocation,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      selectedEntLocation: success ? data : state.selectedEntLocation,
    }),
  },
  // #endregion Location

  // #region Sign
  getSignTypeList: {
    action: getSignTypeList,
    done: (state, { result: { success, data } = {} }) => {
      if (success) {
        const signTypeList = data;
        const signTypeMapId = data.reduce(
          (acc: EntLocSignDataMap, curr: EntLocSignData) => ({
            ...acc,
            [curr.id]: curr,
          }),
          {},
        );
        const signTypeMapName = data.reduce(
          (acc: EntLocSignNameMap, curr: EntLocSignData) => ({
            ...acc,
            [curr.name]: curr,
          }),
          {},
        );
        return {
          ...state,
          signTypeList,
          signTypeMapId,
          signTypeMapName,
        };
      }
      return state;
    },
  },
  getSignRemovalReasonList: {
    action: getSignRemovalReasonList,
    done: (state, { result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            signRemovalReasonList: data,
          }
        : state,
  },
  getEntLocSigns: {
    action: getEntLocSigns,
    done: (state, { result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            selectedEntLocSignList: data,
          }
        : state,
  },
  createEntLocSign: {
    action: createEntLocSign,
    done: (state, { result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            selectedEntLocSignList: [data as EntLocSign, ...state.selectedEntLocSignList],
          }
        : state,
  },
  getEntLocSignById: {
    action: getEntLocSignById,
    done: (state, { result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            selectedEntLocSign: data,
          }
        : state,
  },
  updateEntLocSign: {
    action: updateEntLocSign,
    done: (state, { result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            selectedEntLocSign: data,
          }
        : state,
  },
  resetSelectedEntLocSign: {
    action: resetSelectedEntLocSign,
    reducer: (state) => ({
      ...state,
      selectedEntLocSign: null,
    }),
  },
  removeEntLocSign: {
    action: removeEntLocSign,
    done: (state) => ({
      ...state,
      selectedEntLocSign: null,
    }),
  },
  getShortCodesBySignId: {
    action: getShortCodesBySignId,
    done: (state, { result: { success, data } = {}, params: { signId } }) =>
      success
        ? {
            ...state,
            signShortCodeMap: {
              ...state.signShortCodeMap,
              [signId]: data.shortCodes,
            },
          }
        : state,
  },
  // #endregion Sign

  // #region Billing
  getPaymentMethod: {
    action: getPaymentMethod,
    done: (state, { result: { success, data } = {}, params: { enterpriseId } }) => {
      // api returns ACH as a payment method, but we don't want to show it in the UI
      const isACH = data?.paymentMethod?.paymentMethodType.name === 'ACH Credit Transfer';

      return success && !isACH
        ? {
            ...state,
            entBillingMethodMap: {
              ...state.entBillingMethodMap,
              [enterpriseId]: data.paymentMethod,
            },
          }
        : state;
    },
  },
  // #region Billing
  getAllPaymentMethods: {
    action: getAllPaymentMethods,
    done: (state, { result: { success, data } = {}, params: { enterpriseId } }) =>
      success
        ? {
            ...state,
            entBillingAllMethodMap: {
              ...state.entBillingAllMethodMap,
              [enterpriseId]: data.paymentMethods,
            },
          }
        : state,
  },

  createNewACHPaymentMethod: {
    action: createNewACHPaymentMethod,
    done: (state, { result: { success, data } = {}, params: { enterpriseId } }) =>
      success
        ? {
            ...state,
            entBillingAllMethodMap: {
              ...state.entBillingAllMethodMap,
              [enterpriseId]: data.paymentMethods,
            },
          }
        : state,
  },

  deletePaymentMethod: {
    action: deletePaymentMethod,
    done: (state, { result: { success } = {}, params: { enterpriseId } }) =>
      success
        ? {
            ...state,
            entBillingMethodMap: {
              ...state.entBillingMethodMap,
              [enterpriseId]: null,
            },
          }
        : state,
  },
  getEnterpriseIntakePartners: {
    action: getEnterpriseIntakePartners,
    done: (state, { result: { success, data } = {}, params: { enterpriseId } }) =>
      success
        ? {
            ...state,
            intakePartners: Array.isArray(data) ? data : [],
          }
        : state,
  },
  createEnterpriseIntakePartner: {
    action: createEnterpriseIntakePartner,
    done: (state, { result: { success, data } }) =>
      success
        ? {
            ...state,
            intakePartners: [...state.intakePartners, data],
          }
        : state,
  },
  updateEnterpriseIntakePartner: {
    action: updateEnterpriseIntakePartner,
    done: (state, { result: { success, data } }) => {
      if (success) {
        const filteredIntakePartners = state.intakePartners.filter(
          (partner) => partner.id !== data.id,
        );
        return {
          ...state,
          intakePartners: [...filteredIntakePartners, data],
        };
      }

      return state;
    },
  },
};

export default applyReducers({ store, reducers });
