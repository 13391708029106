import React, { lazy, Suspense } from 'react';

import { AdminRouterParams } from '@m/types';
import { RoleName } from '@m/types/api/iam/Iam';
import { NavLink, Redirect, RouteProps, Switch } from 'react-router-dom';

import { DetailPageWrapper, FormPageWrapper, ListPageWrapper } from 'apps/admin/AdminApp.styled';
import AuthenticatedRoute from 'apps/admin/components/AuthenticatedRoute';
import { navLinkStyle } from 'apps/admin/components/BreadCrumbs/active';
import { DynamicCrumb } from 'apps/admin/components/BreadCrumbs/DynamicCrumb';

const EditZoneContainer = lazy(() => import('apps/admin/containers/location/EditZoneContainer'));
const LocationDetailContainer = lazy(
  () => import('apps/admin/containers/location/LocationDetailContainer'),
);
const LocationListContainer = lazy(
  () => import('apps/admin/containers/location/LocationListContainer'),
);
const LaneDetailContainer = lazy(
  () => import('apps/admin/containers/location/LaneDetailContainer'),
);

export const locationRoutesConfig = [
  // Redirects
  // Zones used to be called locations
  {
    exact: true,
    path: '/location',
    component: () => (
      <Redirect
        to={{
          pathname: '/zones',
        }}
      />
    ),
  },
  {
    path: '/location/',
    component: ({ location }: RouteProps) => (
      <Redirect
        to={{
          pathname: location ? location.pathname.replace('/location/', '/zones/') : '/zones',
        }}
      />
    ),
  },
  // Routes
  {
    exact: true,
    path: '/zones',
    breadcrumb: 'Zones',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <ListPageWrapper>
          <LocationListContainer />
        </ListPageWrapper>
      </Suspense>
    ),
  },
  {
    path: '/zones/:zoneId',
    breadcrumb: ({ match: { params } }: { match: { params: AdminRouterParams } }) => (
      <NavLink to={`/zones/${params.zoneId}/details`} activeStyle={navLinkStyle}>
        <DynamicCrumb itemType="zones" itemId={params.zoneId} />
      </NavLink>
    ),
  },
  {
    exact: true,
    path: '/zones/:zoneId/details',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <DetailPageWrapper>
          <LocationDetailContainer />
        </DetailPageWrapper>
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/zones/:zoneId/edit',
    breadcrumb: 'Edit',
    roles: [RoleName.OPERATIONAL_ADMIN],
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <FormPageWrapper>
          <EditZoneContainer />
        </FormPageWrapper>
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/zones/:zoneId/lane/:laneId',
    roles: [RoleName.OPERATIONAL_ADMIN],
    breadcrumb: ({ match: { params } }: { match: { params: AdminRouterParams } }) => (
      <NavLink to={`/zones/${params.zoneId}/lane/${params.laneId}`} activeStyle={navLinkStyle}>
        <DynamicCrumb itemType="location-lane" itemId={params.laneId} />
      </NavLink>
    ),
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <DetailPageWrapper>
          <LaneDetailContainer />
        </DetailPageWrapper>
      </Suspense>
    ),
  },
];

export default function LocationRoutes() {
  return (
    <Switch>
      {locationRoutesConfig.map(
        ({ component: Component, breadcrumb, ...props }) =>
          Component && <Component key={props.path} {...props} />,
      )}
    </Switch>
  );
}
