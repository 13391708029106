import { applyReducers } from '@m/effector-utils';
import { User } from '@m/types/api';
import { IamUser } from '@m/types/api/iam/Iam';
import { createStore, Effect, Event } from 'effector';

import {
  getUsers,
  getUserById,
  createUserV2,
  updatePermissions,
  setUserActive,
  setSelectedUser,
} from './actions';

import type { DoneHandler } from '@m/effector-utils';
import type { APIResponse } from '@m/utils/http';

type State = {
  userList: User[];
  userMap: {
    [key: number | string]: User;
  };
  iamUserMap: {
    [key: number | string]: IamUser;
  };
};

export const initialState: State = {
  userList: [],
  userMap: {},
  iamUserMap: {}, // only for breadcrumbs atm
};

const store = createStore(initialState);

type Reducers = {
  getUsers: {
    action: Effect<Parameters<typeof getUsers>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getUsers>[0], State>;
  };
  getUserById: {
    action: Effect<Parameters<typeof getUserById>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getUserById>[0], State>;
  };
  createUserV2: {
    action: Effect<Parameters<typeof createUserV2>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof createUserV2>[0], State>;
  };
  updatePermissions: {
    action: Effect<Parameters<typeof updatePermissions>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof updatePermissions>[0], State>;
  };
  setUserActive: {
    action: Effect<Parameters<typeof setUserActive>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof setUserActive>[0], State>;
  };
  setSelectedUser: {
    action: Event<Parameters<typeof setSelectedUser>[0]>;
    reducer: (state: State, payload: Parameters<typeof setSelectedUser>[0]) => State;
  };
};

export const reducers: Reducers = {
  getUsers: {
    action: getUsers,
    done: (state, { result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            userList: data.users,
          }
        : state,
  },
  getUserById: {
    action: getUserById,
    done: (state, { result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            userMap: {
              ...state.userMap,
              [data.user.id]: data.user,
            },
          }
        : state,
  },
  createUserV2: {
    action: createUserV2,
    done: (state, { result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            userList: [data.user, ...state.userList],
            userMap: {
              ...state.userMap,
              [data.user.id]: data.user,
            },
          }
        : state,
  },
  updatePermissions: {
    action: updatePermissions,
    done: (state, { result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            userMap: {
              ...state.userMap,
              [data.user.id]: data.user,
            },
          }
        : state,
  },
  setUserActive: {
    action: setUserActive,
    done: (state, { result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            userMap: {
              ...state.userMap,
              [data.user.id]: data.user,
            },
          }
        : state,
  },
  setSelectedUser: {
    action: setSelectedUser,
    reducer: (state, user) => {
      if (user?.id) {
        return {
          ...state,
          iamUserMap: {
            ...state.iamUserMap,
            [user.id]: user,
          },
        };
      }
      return state;
    },
  },
};

export default applyReducers({ store, reducers });
