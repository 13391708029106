import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPoliceOfficer1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor">
      <path d="M7 10c0 2.757 2.243 5 5 5s5-2.243 5-5v-.764l2.41-4.821L12 1.946l-7.41 2.47L7 9.236zm5 3c-1.654 0-3-1.346-3-3h6c0 1.654-1.346 3-3 3m0-8.945 4.59 1.529L15.382 8H8.618L7.41 5.584z" />
      <circle cx={12} cy={6} r={1} />
      <path d="M12 16c-4.71 0-8 2.467-8 6v2h16v-2c0-3.533-3.29-6-8-6m-1 2.051V22H6c0-2.125 2.012-3.667 5-3.949M13 22v-3.949c2.988.282 5 1.824 5 3.949z" />
    </g>
  </svg>
);
export default SvgPoliceOfficer1;
