import {
  createSystem,
  defaultConfig,
  defineConfig,
  defineRecipe,
  defineTokens,
} from '@chakra-ui/react';
import { fontDefault, fontMonospaced } from '@m/styled/fonts';

export const buttonRecipe = defineRecipe({
  base: {
    padding: '16px',
    fontSize: '16px',
  },
  variants: {
    size: {
      sm: {
        padding: '10px',
        fontSize: '14px',
      },
    },
    variant: {
      primary: {
        bg: 'gray.900',
        color: 'gray.000',
        _hover: {
          bg: 'gray.700',
        },
        _active: {
          bg: 'gray.600',
        },
        _disabled: {
          bg: 'gray.100',
          color: 'gray.500',
        },
      },
      secondary: {
        bg: 'gray.200',
        color: 'gray.900',
        _hover: {
          bg: 'gray.300',
        },
        _active: {
          bg: 'gray.400',
        },
        _disabled: {
          bg: 'gray.100',
          color: 'gray.500',
        },
      },
      tertiary: {
        bg: 'gray.000',
        borderColor: 'gray.400',
        color: 'gray.900',
        _hover: {
          bg: 'gray.100',
          borderColor: 'gray.500',
        },
        _active: {
          bg: 'gray.200',
          borderColor: 'gray.600',
        },
        _disabled: {
          bg: 'gray.000',
          color: 'gray.500',
          borderColor: 'gray.200',
        },
      },
      brand: {
        bg: 'brand.300',
        color: 'gray.000',
        _hover: {
          bg: 'brand.400',
        },
        _active: {
          bg: 'brand.500',
        },
        _disabled: {
          bg: 'brand.000',
          color: 'gray.600',
        },
      },
    },
    destructive: {
      true: {},
    },
  },
  compoundVariants: [
    {
      destructive: true,
      variant: 'primary',
      css: {
        bg: 'red.300',
        _hover: {
          bg: 'red.400',
        },
        _active: {
          bg: 'red.500',
        },
        _disabled: {
          bg: 'red.000',
          color: 'gray.600',
        },
      },
    },
    {
      destructive: true,
      variant: 'secondary',
      css: {
        bg: 'gray.000',
        borderColor: 'red.400',
        color: 'red.400',
        _hover: {
          bg: 'red.000',
        },
        _active: {
          bg: 'red.000',
          borderColor: 'red.500',
          color: 'red.400',
        },
        _disabled: {
          bg: 'gray.000',
          borderColor: 'red.100',
          color: 'red.200',
        },
      },
    },
  ],
});

export const tokens = defineTokens({
  fonts: {
    body: { value: fontDefault },
    heading: { value: fontDefault },
    mono: { value: fontMonospaced },
  },
  radii: {
    sm: { value: '6px' },
  },
  colors: {
    // https://www.figma.com/design/ebpCij6CDTHYedGBKuiwhF/Design-System--%3E-Library-(WIP)?node-id=1-31&m=dev
    gray: {
      '000': { value: '#FFFFFF' },
      100: { value: '#F5F5F5' },
      200: { value: '#F0F0F0' },
      300: { value: '#DFDFDF' },
      400: { value: '#CCCCCC' },
      500: { value: '#9E9E9E' },
      600: { value: '#5E5E5E' },
      700: { value: '#373737' },
      800: { value: '#181818' },
      900: { value: '#000000' },
    },
    red: {
      '000': { value: '#FFF0EE' },
      100: { value: '#FFC6BE' },
      200: { value: '#FF6652' },
      300: { value: '#D4311C' },
      400: { value: '#A51F0F' },
      500: { value: '#891406' },
      600: { value: '#761104' },
    },
    green: {
      '000': { value: '#DFFFEF' },
      100: { value: '#95E8BD' },
      200: { value: '#25BD6F' },
      300: { value: '#0F8F4D' },
      400: { value: '#0E733F' },
      500: { value: '#0D5C33' },
      600: { value: '#064F2A' },
    },
    yellow: {
      200: { value: '#FFE359' },
    },
    brand: {
      '000': { value: '#F7F7FF' },
      100: { value: '#E0DFFF' },
      200: { value: '#ABA8FF' },
      300: { value: '#5F59FF' },
      400: { value: '#4842DF' },
      500: { value: '#2D28A7' },
      600: { value: '#07036C' },
    },
  },
});

const customConfig = defineConfig({
  theme: {
    recipes: {
      button: buttonRecipe,
    },
    tokens,
    semanticTokens: {
      colors: {
        icon: {
          interactive: { value: 'gray.900' },
          descriptive: { value: 'gray.600' },
          disabled: { value: 'gray.500' },
          white: { value: 'gray.000' },
          error: { value: 'red.300' },
        },
        text: {
          primary: { value: 'gray.900' },
          secondary: { value: 'gray.600' },
          tertiary: { value: 'gray.400' },
          'read-only': { value: 'gray.700' },
          disabled: { value: 'gray.500' },
          white: { value: 'gray.000' },
          error: { value: 'red.300' },
          discount: { value: 'green.300' },
        },
        status: {
          error: { value: 'red.300' },
          success: { value: 'green.300' },
        },
        fill: {
          background: {
            default: { value: 'gray.000' },
            'light-gray': { value: 'gray.100' },
          },
          interactive: {
            enabled: { value: 'gray.000' },
            hover: { value: 'gray.100' },
            selected: { value: 'gray.200' },
            'hover-selected': { value: 'gray.300' },
          },
          input: {
            enabled: { value: 'gray.000' },
            hover: { value: 'gray.100' },
            active: { value: 'gray.000' },
            focused: { value: 'gray.000' },
            'read-only': { value: 'gray.200' },
            disabled: { value: 'gray.000' },
            error: { value: 'gray.000' },
            filled: { value: 'gray.000' },
          },
          button: {
            primary: {
              enabled: { value: 'gray.900' },
              hover: { value: 'gray.800' },
              active: { value: 'gray.700' },
              focused: { value: 'gray.900' },
              selected: { value: 'gray.900' },
              disabled: { value: 'gray.100' },
            },
            secondary: {
              enabled: { value: 'gray.200' },
              hover: { value: 'gray.300' },
              active: { value: 'gray.400' },
              focused: { value: 'gray.200' },
              selected: { value: 'gray.700' },
              disabled: { value: 'gray.100' },
            },
            tertiary: {
              enabled: { value: 'gray.000' },
              hover: { value: 'gray.100' },
              active: { value: 'gray.200' },
              focused: { value: 'gray.000' },
              selected: { value: 'gray.000' },
              disabled: { value: 'gray.000' },
            },
            pay: {
              enabled: { value: 'brand.300' },
              hover: { value: 'brand.400' },
              active: { value: 'brand.500' },
              focused: { value: 'brand.300' },
              selected: { value: 'brand.600' },
              disabled: { value: 'brand.000' },
            },
          },
        },
        stroke: {
          border: {
            default: { value: 'gray.500' },
            disabled: { value: 'gray.300' },
            focused: { value: 'gray.900' },
            'focused-wrapper': { value: 'gray.000' },
            hover: { value: 'gray.600' },
            error: { value: 'red.300' },
            menu: { value: 'gray.300' },
          },
          button: {
            tertiary: {
              enabled: { value: 'gray.400' },
              hover: { value: 'gray.500' },
              active: { value: 'gray.600' },
              focused: { value: 'gray.900' },
              selected: { value: 'gray.900' },
              disabled: { value: 'gray.200' },
            },
          },
          divider: {
            default: { value: 'gray.200' },
          },
        },
      },
    },
  },
  globalCss: {
    '*::selection': {
      bg: '',
    },
  },
});

export const system = createSystem(defaultConfig, customConfig);
