import { KEYCLOAK_ENABLED } from '@m/config/env';
import { CONNECT_TO_EXTERNAL_API } from '@m/constants/FeatureFlags';
import { KeycloakService } from '@m/keycloak-service/KeycloakService';
import GoogleAuth from '@m/utils/GoogleAuth';
import { heapClearEventProperties, heapIdentityReset } from '@m/utils/heap';
import { apiFetch, setAuthToken } from '@m/utils/http';
import { createEffect } from 'effector';

import { userAnalytics } from 'utils/userAnalytics';

let gAuth: GoogleAuth;

export const getCurrentUser = createEffect({
  handler: () => apiFetch('/admin/user'),
});

export const login = createEffect({
  handler: async () => {
    if (!gAuth) {
      gAuth = new GoogleAuth();
    }
    const { email, code, provider } = await gAuth.login();
    const response = await apiFetch('/login', {
      method: 'POST',
      body: {
        login: email,
        code,
        provider,
        sessionMode: CONNECT_TO_EXTERNAL_API ? 'token' : undefined,
      },
    });

    CONNECT_TO_EXTERNAL_API && setAuthToken(response?.data?.token);
    return response;
  },
});

export const loginMicrosoft = createEffect({
  handler: async ({ email, code }: { email: string; code: string }) => {
    const response = await apiFetch('/login', {
      method: 'POST',
      body: {
        login: email,
        code,
        provider: 'microsoft',
        sessionMode: CONNECT_TO_EXTERNAL_API ? 'token' : undefined,
      },
    });

    CONNECT_TO_EXTERNAL_API && setAuthToken(response?.data?.token);
    return response;
  },
});

export const logout = createEffect({
  handler: async () => {
    if (KEYCLOAK_ENABLED && KeycloakService.isLoggedIn()) {
      await KeycloakService.doLogout();
      userAnalytics.unsetUser();
      return { success: true };
    }

    const logoutResp = await apiFetch('/logout', { method: 'POST' });

    if (logoutResp.success) {
      heapIdentityReset();
      heapClearEventProperties();
      userAnalytics.unsetUser();

      if (!gAuth) {
        gAuth = new GoogleAuth();
      }
      await gAuth.logout();
    }

    return logoutResp;
  },
});
