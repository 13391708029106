import * as React from 'react';
import type { SVGProps } from 'react';

const SvgEnterprise = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" {...props}>
    <path d="M6 6h1.5v3H6zm0 4.5h1.5v3H6zM10.5 6H12v3h-1.5zm0 4.5H12v3h-1.5zM6 15h1.5v3H6zm4.5 0H12v3h-1.5z" />
    <path d="M22.5 10.5c0-.83-.67-1.5-1.5-1.5h-4.5V3c0-.83-.67-1.5-1.5-1.5H3c-.83 0-1.5.67-1.5 1.5v19.5h21zM3 3h12v18H3zm13.5 18V10.5H21V21z" />
  </svg>
);
export default SvgEnterprise;
