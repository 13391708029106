import React, { HTMLAttributes } from 'react';

import { Wrapper } from './EnvironmentBadge.styled';
import { getBadgeData } from './EnvironmentBadge.utils';

import type { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';

type EnvironmentBadgeProps = HTMLAttributes<HTMLDivElement> &
  StyledSystemProps & {
    compact?: boolean;
  };

export default function EnvironmentBadge({ compact, ...passProps }: EnvironmentBadgeProps) {
  const badgeData = getBadgeData();

  if (!badgeData) {
    return null;
  }

  const { label, shortLabel, color: backgroundColor } = badgeData;

  return (
    <Wrapper backgroundColor={backgroundColor} {...passProps}>
      <div>{compact ? shortLabel : label}</div>
    </Wrapper>
  );
}
