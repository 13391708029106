import { NavLink, useLocation } from 'react-router-dom';
import useBreadCrumbs from 'use-react-router-breadcrumbs';

import { customerRoutesConfig } from 'apps/admin/routes/CustomerRoutes';
import { devToolRoutesConfig } from 'apps/admin/routes/DevToolRoutes';
import { enforcementRoutesConfig } from 'apps/admin/routes/EnforcementRoutes';
import { enterpriseRoutesConfig } from 'apps/admin/routes/EnterpriseRoutes';
import { featureFlagsRoutesConfig } from 'apps/admin/routes/FeatureFlagRoutes';
import { locationGroupRoutesConfig } from 'apps/admin/routes/LocationGroupRoutes';
import { locationRoutesConfig } from 'apps/admin/routes/LocationRoutes';
import { resourceGroupRoutesConfig } from 'apps/admin/routes/ResourceGroupRoutes';
import { userRoutesConfig } from 'apps/admin/routes/UserRoutes';
import { visitRoutesConfig } from 'apps/admin/routes/VisitRoutes';

import { navLinkStyle } from './active';
import { Wrap, BreadCrumb } from './BreadCrumbs.styled';

const routeConfig = [
  ...enterpriseRoutesConfig,
  ...locationGroupRoutesConfig,
  ...locationRoutesConfig,
  ...userRoutesConfig,
  ...customerRoutesConfig,
  ...featureFlagsRoutesConfig,
  ...enforcementRoutesConfig,
  ...visitRoutesConfig,
  ...devToolRoutesConfig,
  ...resourceGroupRoutesConfig,
];

export function BreadCrumbs() {
  const { pathname } = useLocation();
  const crumbs = useBreadCrumbs(routeConfig, {
    disableDefaults: true,
  });
  const count = crumbs.length;

  return (
    <Wrap key={pathname}>
      {crumbs.map(({ match, breadcrumb }, index) => {
        // @ts-ignore
        const isCustom = typeof breadcrumb?.type === 'function';
        return breadcrumb ? (
          <BreadCrumb key={`${match.url}`} index={index} count={count}>
            {isCustom ? (
              breadcrumb
            ) : (
              <NavLink to={match.url} exact activeStyle={navLinkStyle}>
                {breadcrumb}
              </NavLink>
            )}
          </BreadCrumb>
        ) : null;
      })}
    </Wrap>
  );
}
