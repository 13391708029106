import { fadeIn } from '@m/styled/keyframes';
import { Layout } from 'antd';
import { Users } from 'react-feather';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const { Sider } = Layout;

export const Nav = styled(Sider)<{ $expanded?: boolean }>`
  animation: ${fadeIn} 200ms linear 0ms 1 backwards;
  & + * {
    padding-left: ${({ $expanded }) => ($expanded ? 120 : 0)}px;
    transition: padding 200ms ease 0ms;
  }
`;

export const LogoArea = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 16px;
`;

export const LogoWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  flex-shrink: 0;

  & > svg {
    display: block;
    width: 34px;
    height: 34px;
    fill: ${({ theme }) => theme.colors.brand};
  }
`;

export const WordmarkWrap = styled.div<{ $expanded?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: 40px;
  flex-shrink: 0;
  margin-left: 8px;
  transition-property: opacity, transform;
  transition-timing-function: linear, ease-out;
  transition-duration: ${({ $expanded }) => ($expanded ? '350ms, 300ms' : '150ms, 350ms')};
  transition-delay: ${({ $expanded }) => ($expanded ? '50ms, 0ms' : '0ms, 0ms')};
  opacity: ${({ $expanded }) => ($expanded ? 1 : 0)};
  transform: translate3d(${({ $expanded }) => ($expanded ? 0 : -54)}px, 0, 0)
    scale3d(
      ${({ $expanded }) => ($expanded ? 1 : 0.75)}px,
      ${({ $expanded }) => ($expanded ? 1 : 0.75)}px,
      1
    );

  & > svg {
    display: block;
    width: 101px;
    height: 34px;
    fill: ${({ theme }) => theme.colors.brand};
  }
`;

export const UserIcon = styled(Users)`
  width: 16px;
  height: 16px;
`;

export const MenuItemContent = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  width: calc(100% + 60px);
  height: 100%;
  margin-left: -24px;
  margin-right: -16px;
  padding-left: 24px;
  padding-right: 16px;
`;
