import { KEYCLOAK_ENABLED, SITE_API_BASE_URL } from '@m/config/env';
import { KeycloakService } from '@m/keycloak-service/KeycloakService';
import { stringify } from 'query-string';

import { getAuthToken, getDefaultAuthMethod } from './apiUtils';

import type { APIResponse, FetchConfig, FetchOptions } from './types';

export async function apiFetch<ResponseData = any>(
  endpoint: string,
  {
    method = 'GET',
    headers = {},
    params,
    body,
    stringifyOpts,
    sendRawBody,
    statusCodes = false,
    isPolling = false,
  }: FetchOptions & { isPolling?: boolean } = {},
  { authMethod = getDefaultAuthMethod() }: FetchConfig = {},
): Promise<APIResponse<ResponseData>> {
  if (isPolling && document.visibilityState !== 'visible') {
    // Browser tab is not active. Skipping the request.
    return { success: false };
  }

  const shouldStringifyBody = !sendRawBody;

  const fetchOptions: RequestInit & { headers: Record<string, string> } = {
    method,
    headers,
  };

  if (shouldStringifyBody) {
    fetchOptions.headers['Content-Type'] = 'application/json';
  }

  if (KEYCLOAK_ENABLED && KeycloakService.isLoggedIn()) {
    await KeycloakService.updateToken(() => {
      const authToken = KeycloakService.getToken();
      if (authToken) {
        fetchOptions.headers.Authorization = `Bearer ${authToken}`;
      }
    });
  } else if (authMethod === 'token') {
    const authToken = await getAuthToken();
    if (authToken) {
      fetchOptions.headers.Authorization = authToken;
    }
  } else if (authMethod === 'cookie') {
    fetchOptions.credentials = 'include';
  }
  if (body && shouldStringifyBody) {
    // Format body as JSON
    fetchOptions.body = JSON.stringify(body);
  } else if (body) {
    // Send body without any modification
    fetchOptions.body = body;
  }

  let query = '';
  if (params && typeof params === 'object' && Object.keys(params).length > 0) {
    query = `?${stringify(params, stringifyOpts)}`;
  }
  const reqUrl = `${SITE_API_BASE_URL}${endpoint}${query}`;

  return fetch(reqUrl, fetchOptions)
    .then(async (response) => {
      if (statusCodes) {
        return response.json();
      }
      if (response.status === 200) {
        return response.json();
      }
      const data = await response.json().catch((e: any) => {
        // if response isn't a json, then we return no data
        // idk if we want to display 'Gateway Timeout' or 'Unexpected Error' to the client, so setting the error to undefined
        // eslint-disable-next-line no-console
        console.log('e', e);
        return undefined;
      });

      return {
        success: false,
        status: response.status,
        statusText: response.statusText,
        ...(typeof data === 'object' ? data : { error: data }),
      };
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e);
      if (statusCodes) {
        throw e;
      }
      return { success: false };
    });
}
